<template>
    <div class="pt-2 full-stage-fit">
        <b-row v-if="batchSent">
            <b-col v-if="batchSending" cols="12">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
                <h1>Sending batch Please Stand By</h1>
            </b-col>
            <b-col v-else cols="12">
                <h1>Batch Sent</h1>
                <b-button :to="{ path: '/home/batchmanager' }">Go To Batch Manager To View Batch Progress</b-button>
            </b-col>
        </b-row>
        <b-row v-if="!batchSent" class="mx-0">
            <b-col cols="12" v-if="!listIDSelected">
                <h4>First, select a saved list that you'd like to use to generate the letters.</h4>
            </b-col>
            <b-col cols="12">
                <b-row>
                    <b-col md="12" xl="6">
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="4"
                            label-cols-xl="3"
                            :label="(localListID == null) ? 'Select A List' : 'Selected List'" 
                            label-for="list-id-selection"
                        >
                            <b-form-select 
                                id="list-id-selection" 
                                v-model="localListID" 
                                :options="selectableListsOptions" 
                                @change="listIDChange"
                            >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="2" xl="1" v-if="allListOptions.filter((ll)=>{ return ll.id == localListID }).length > 0">
                        Description
                    </b-col>
                    <b-col sm="12" md="10" xl="5" v-if="allListOptions.filter((ll)=>{ return ll.id == localListID }).length > 0">
                        {{( allListOptions.filter((ll)=>{ return ll.id == localListID })[0].description != null && allListOptions.filter((ll)=>{ return ll.id == localListID })[0].description.length > 200 ) ? allListOptions.filter((ll)=>{ return ll.id == localListID })[0].description.substring(0, 200) + '...' : allListOptions.filter((ll)=>{ return ll.id == localListID })[0].description }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="!batchSent && !startUpComplete && listIDSelected && !emailTemplateIDSelected" class="mx-0">
            <b-col cols="12" v-if="!emailTemplateIDSelected">
                <h4>Use an existing letter template or create a new template?</h4>
            </b-col>
            <b-col cols="10" v-if="!emailTemplateIDSelected">
                <b-row>
                    <b-col md="12" xl="6">
                        <b-form-group
                            label-cols-sm="12"
                            label-cols-md="4"
                            label-cols-xl="3"
                            label="Use Existing Template" 
                            label-for="list-id-selection"
                        >
                            <b-form-select 
                                id="list-id-selection" 
                                v-model="localEmailTemplateID" 
                                :options="selectableEmailTemplateOptions" 
                                @change="existingEmailTemplateSelected"
                            >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="2" xl="1" v-if="allEmailTemplateOptions.filter((et)=>{ return et.id == localEmailTemplateID }).length > 0">
                        Description
                    </b-col>
                    <b-col sm="12" md="10" xl="5" v-if="allEmailTemplateOptions.filter((et)=>{ return et.id == localEmailTemplateID }).length > 0">
                        {{( allEmailTemplateOptions.filter((et)=>{ return et.id == localEmailTemplateID })[0].description != null && allEmailTemplateOptions.filter((et)=>{ return et.id == localEmailTemplateID })[0].description.length > 200 ) ? allEmailTemplateOptions.filter((et)=>{ return et.id == localEmailTemplateID })[0].description.substring(0, 200) + '...' : allEmailTemplateOptions.filter((et)=>{ return et.id == localEmailTemplateID })[0].description }}
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="2" >
                <b-button variant="info" @click="setEmailTemplateNew">Create New Template</b-button>
            </b-col>
        </b-row>
        <b-row v-if="!batchSent && isNewEmailTemplate">
            <!-- Is New Email Template -->
            <b-col cols="12">
                <b-row>
                    <b-col md="12" lg="6" xl="3">
                        <b-row>
                            <b-col>
                                <b-form-group
                                    id="template-name-group"
                                    label="Letter Template Name"
                                    label-for="template-name"
                                >
                                    <b-form-input id="template-name" v-model="emailTemplateActive.name" trim></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                    id="template-notice-type"
                                    label="Letter Notice Type" 
                                    label-for="notice-type-selection"
                                >
                                    <b-form-select 
                                        id="notice-type-selection" 
                                        v-model="selectedNoticeType" 
                                        :options="noticeTypes"
                                    >
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="12" lg="6" xl="3">
                        <b-form-group
                            id="template-desc-group"
                            label="Letter Template Description"
                            label-for="template-desc"
                        >
                            <b-form-textarea id="template-desc" v-model="emailTemplateActive.description" trim></b-form-textarea>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" lg="6" xl="3">
                        <b-form-group label="Template Sharing" v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                id="template-share-options"
                                v-model="emailTemplateActive.shareBehavior"
                                :aria-describedby="ariaDescribedby"
                                name="radio-sub-component"
                                stacked
                            >
                                <b-form-radio @change="updatePermissions" value="Only For Me">Only For Me</b-form-radio>
                                <b-form-radio @change="updatePermissions" value="Company-Wide (Read-Only)">Company-Wide (Read-Only)</b-form-radio>
                                <b-form-radio @change="updatePermissions" value="Company-Wide (Editable)">Company-Wide (Editable)</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" lg="6" xl="3">
                        <b-button block @click="createNewTemplate">Save Template</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="!batchSent && !isNewEmailTemplate && (emailTemplateActive.owned_by_me || (!emailTemplateActive.owned_by_me && emailTemplateActive.account_shared && emailTemplateActive.allow_account_editing))"> 
            <!-- If The Email Template Chosen Can Be Edited -->
            <b-col cols="12">
                <b-row>
                    <b-col md="12" lg="6" xl="3">
                        <b-row>
                            <b-col>
                                <b-form-group
                                    id="template-name-group"
                                    label="Letter Template Name"
                                    label-for="template-name"
                                >
                                    <b-form-input id="template-name" v-model="emailTemplateActive.name" trim></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                    id="template-notice-type"
                                    label="Letter Notice Type" 
                                    label-for="notice-type-selection"
                                >
                                    <b-form-select 
                                        id="notice-type-selection" 
                                        v-model="emailTemplateActive.noticeType" 
                                        :options="noticeTypes"
                                    >
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                    </b-col>
                    <b-col md="12" lg="6" xl="3">
                        <b-form-group
                            id="template-desc-group"
                            label="Letter Template Description"
                            label-for="template-desc"
                        >
                            <b-form-textarea id="template-desc" v-model="emailTemplateActive.description" trim></b-form-textarea>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" lg="6" xl="3">
                        <b-form-group label="Template Sharing" v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                id="template-share-options"
                                v-model="emailTemplateActive.shareBehavior"
                                :aria-describedby="ariaDescribedby"
                                name="radio-sub-component"
                                stacked
                            >
                                <b-form-radio @change="updatePermissions" value="Only For Me">Only For Me</b-form-radio>
                                <b-form-radio @change="updatePermissions" value="Company-Wide (Read-Only)">Company-Wide (Read-Only)</b-form-radio>
                                <b-form-radio @change="updatePermissions" value="Company-Wide (Editable)">Company-Wide (Editable)</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" lg="6" xl="3">
                        <b-button block @click="updateTemplate">Save Template Update</b-button>
                        <b-button block @click="saveTemplateCopy">Save Template As Copy</b-button>
                        <b-button v-if="isAnyEditorDirty" block @click="saveThenFinalCheckBeforeBatchSend">Save Template & Send Batch</b-button>
                        <b-button v-else-if="!isAnyEditorDirty" block @click="finalCheckBeforeBatchSend">Send Batch</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="!batchSent && !isNewEmailTemplate && ((!emailTemplateActive.owned_by_me && emailTemplateActive.account_shared && !emailTemplateActive.allow_account_editing) || (!emailTemplateActive.owned_by_me && emailTemplateActive.global_shared))"> 
            <!-- If The Email Template Chosen Cannot Be Edited, But The User Could Save A Copy -->
            <b-col>
                <h4>Letter Template Is Not Editable</h4>
                <b-button block @click="createEditableCopy">Create Editable Copy</b-button>
            </b-col>
        </b-row>
        <b-row v-if="!batchSent && emailTemplateActive.owned_by_me && emailTemplateActive.global_shared"> 
            <h3>WARNING THIS IS A GLOBALLY SHARED TEMPLATE - SAVED EDITS WILL BE PROPIGATED TO ALL SHARED TEMPLATE USERS</h3>
        </b-row>
        <b-row v-if="!batchSent" :class="((startUpComplete) ? 'show-editor' : 'dont-show-editor') + ' mx-0'">
            <b-col lg="12" xl="6">
                <div>
                    <h4>When Letters From This Template Are Sent, Where Should An Event Be Written</h4>
                </div>
                <div>
                    <b-form-select v-model="emailParentSelected" :options="emailParentOptions"></b-form-select>
                </div>
                <div class="mt-1">
                    <h4>Letter</h4>
                </div>
                <div>
                    <textarea id="tinymce-editor"></textarea>
                </div>
            </b-col>
            <b-col lg="12" xl="6">
                <b-row>
                    <b-col sm="4" md="3" lg="2">
                        <h3>Preview</h3>
                    </b-col>
                    <b-col cols="*">
                        <b-button-toolbar key-nav aria-label="Toolbar with button groups">
                            <b-button-group class="mx-1">
                                <b-button :disabled="currentlyPreviewing == 0" @click="previewLast">&lsaquo;</b-button>
                            </b-button-group>
                            <b-button-group class="mx-1">
                                <b-button disabled >{{currentlyPreviewing + 1}} / {{this.items.length}}</b-button>
                            </b-button-group>
                            <b-button-group class="mx-1">
                                <b-button :disabled="currentlyPreviewing == items.length - 1" @click="previewNext">&rsaquo;</b-button>
                            </b-button-group>
                            <b-button-group class="mx-1">
                                <b-button :disabled="previewAwaiting" @click="updatePreview">Update</b-button>
                            </b-button-group>
                        </b-button-toolbar>
                    </b-col>
                </b-row>
                <b-row v-if="previewAwaiting" class="justify-content-md-center">
                    <div>
                        <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                </b-row>
                <b-row v-if="previewAwaiting" class="justify-content-md-center">
                    <div>
                        {{previewAwaitingText}}
                    </div>
                </b-row>
                <div id="pdf-preview"></div>
            </b-col>
        </b-row>
        <b-modal v-model="showGenModal" hide-footer :title="genModal.title" size="lg">
            <div v-for="(bdy, index) in genModal.body" :key="index">
                {{bdy}}
            </div>
            <b-button class="mt-3" block @click="hideGeneralModal">OK</b-button>
        </b-modal>
        <b-modal v-model="showTemplateMismatchModal" hide-footer :title="templateMismatchModal.title" size="lg">
            <div v-for="(bdy, index) in templateMismatchModal.body" :key="index">
                {{bdy}}
            </div>
            <b-button class="mt-3" block @click="hideTemplateMismatchModal">OK</b-button>
        </b-modal>
        <b-modal v-model="showWarningModal" hide-footer :title="warnModal.title" size="lg" header-bg-variant="info">
            <b-row>
                <b-col>
                    <h4>This Will Create A Batch With {{warnModal.numberToCreate}} Letters</h4>
                </b-col>
            </b-row>
            <b-row v-if="warnModal.cannotBeAttachedDueToMissingParent > 0">
                <b-col>
                    <h3 class="text-danger">WARNING</h3>
                <h4 class="text-danger">{{warnModal.cannotBeAttachedDueToMissingParent}} Letters Will Not Be Attached To The Parent Specified Because The Parent Specified In The List Is Not Valid</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                        id="batch-name-group"
                        :description="`If nothing is entered here, we will use the default name: '${warnModal.defaultName}' `"
                        placeholder="Pick a descriptive name for this batch"
                        label="Batch Name"
                        label-for="batch-name"
                    >
                        <b-form-input id="batch-name" v-model="warnModal.batchName" trim></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <div>
                
            </div>
            <b-button class="mt-3" block variant="success" @click="sendRequestForBatch">Generate Batch</b-button>
            <b-button class="mt-3" block @click="hideWarningModal">Cancel</b-button>
        </b-modal>
    </div>
</template>

<script>
// Modules
const _ = require('underscore');
const ejs = require('ejs');
const cloneDeep = require('lodash.clonedeep');
const pdfObject = require('pdfobject');
// Libraries
const butils = require('../../libs/basicUtils.js');

export default {
    name: 'letterbuilder',
    components:{
        // pdf
    },
    props:{
        emailTemplateID: {
            type: String,
            default: () => { return null }
        },
        listID: {
            type: String,
            default: () => { return null }
        }
    },
    data(){
        return{
            // PDF Preview
            previewAwaiting: true,
            previewAwaitingText: "Preview Loading - Please Wait",
            pdfReady: false,
            pdfSrc: null,
            // Batch Sent
            batchSent: false,
            batchSending: false,
            // Warning Modal
            showWarningModal: false,
            warnModal:{
                title: "Batch Letter Generation",
                numberToCreate: null,
                batchName: null,
                defaultName: null,
                cannotBeAttachedDueToMissingParent: 0,
            },
            // General Modal
            showGenModal: false,
            genModal:{
                title: null,
                body: []
            },
            // Template Mismatch Modal
            showTemplateMismatchModal: false,
            templateMismatchModal:{
                title: null,
                body: []
            },
            // Local Copies of Prop
            localEmailTemplateID: null,
            localListID: null,
            // Loadup Behaviors
            listIDSelected: false,
            isNewEmailTemplate: null,
            emailTemplateIDSelected: false,
            askIfUsingTemplateDefaultList: false,
            startUpComplete: false,
            callWhenReady: null,
            // Email Templates
            originalLoadedTemplate: null,
            allEmailTemplateOptions: [],
            selectableEmailTemplateOptions: [],
            emailTemplateActive:{
                id: null,
                recipient: null,
                owned_by_me: false,
                account_shared: false,
                allow_account_editing: false,
                global_shared: false,
                name: null,
                description: null,
                shareBehavior: 'Only For Me',
                isNotice: null,
                noticeType: null           
            },
            // Load and Save Stuff
            // Preview Info
            ejsPreppedPreview: null,
            currentPreviewRow: null,
            currentlyPreviewing: 0,
            // TinyMCE Stuff
            startBodyContentValue: '',
            startSubjectContentValue: '',
            // Attachment of Event To Parent Stuff
            emailParentSelected: null,
            emailParentOptions: [],
            // Email Recipient Stuff
            emailRecipientOptions: [],
            emailRecipientPreview: null,
            // Subject Stuff
            subjectInitValue: null,
            subjectTinyMCE: null,
            subjectPreRender: null,
            subjectPostRender: null,
            beforeRenderFixes: null,
            ejsVars: {},
            ejsVarsLinear: [],
            ejsVarGroups: [],
            readyCompleted: false,
            tinymce: null,
            initValue: null,
            interval: null,
            preRenderHTML: null,
            postRenderHTML: null,
            preRenderTextValue: null,
            jsonTemplateValue: null,
            // List Builder Stuff
            waiting: false,
            allListOptions: [],
            selectableListsOptions: [],
            rawAvailableFields: [],
            tree: [],
            loadedFromPackage: null,
            savePackage:{
                id: null,
                name: null,
                description: null,
                disabled: false,
                permissions: 'user'
            },
            fields: [],
            items: [],
            filterElems: [],
            currentlySortedBy: null,
            sortBy: null,
            sortDesc: null,
            sortHeader: null,
            sortFunction: null,
            rawItems: [],
            items: [],
            limitedItemsForDisplay: [],
            setLimit: 1000,
            moreThanLimitItems: false,
            filterOperatorsPerType: {
                text: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'},
                    { text: 'contains (case-insensitive)', value:'CONTAINS'},
                    { text: 'does not contain (case-insensitive)', value:'NOTCONTAINS'}
                ],
                numeric: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                ],
                integer: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                ],
                'timestamp with time zone':[
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'more than X days ago', value: 'OLDERTHANDAYSAGO'},
                    { text: 'within the last X days', value: 'YOUNGERTHANDAYSAGO'},
                    { text: 'last month', value: 'LASTMONTH'},
                    { text: 'last 90 days', value: 'LAST90DAYS'},
                    { text: 'last year', value: 'LASTYEAR'},
                    { text: 'this year', value: 'THISYEAR'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                    
                ],
                'text[]':[
                    { text: 'includes the element (case-sensitive)', value: 'ARRAYCONTAINS'},
                    { text: 'includes an element like (case-insensitive)', value: 'ARRAYCONTAINSLIKE'},
                    { text: 'NOT including the element (case-sensitive)', value: 'ARRAYNOTCONTAINS'},
                    { text: 'NOT including an element like (case-insensitive)', value: 'ARRAYNOTCONTAINSLIKE'},
                    { text: 'Contains At Least One Element', value: 'ARRAYLENGTHATLEASTONE'},
                ],
                boolean: [
                    { text: 'is', value: 'EQUAL'},
                ]
            },
            baseFileURL: process.env.VUE_APP_FILE_API_BASE_URL,
            baseSiteURL: process.env.VUE_APP_API_BASE_URL,
            debouncedUpdate: ()=>{},
            noticeTypes: [],
            selectedNoticeType: null
        }
    },
    methods:{
        previewLast(){
            if(this.currentlyPreviewing > 0){
                this.currentlyPreviewing--;
            }
            this.debouncedUpdate();
        },
        previewNext(){
            if(this.items.length - 1 > this.currentlyPreviewing){
                this.currentlyPreviewing++;
            }
            this.debouncedUpdate();
        },
        ready(){
            this.pdfPreviewIntervalStart = new Date().getTime();
            this.tinymce = window.tinymce;
            var useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

            this.initValue = {
                extended_valid_elements: 'ejsinline',
                custom_element: 'ejsinline',
                selector: 'textarea#tinymce-editor',
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'edit view insert format tools table help',
                toolbar: 'variables | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                autosave_ask_before_unload: false,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                entity_encoding: "raw",
                image_advtab: true,
                pagebreak_separator: '<div style="page-break-after:always;"><!-- pagebreak --></div>',
                pagebreak_split_block: true,
                // image_list: (cb)=>{
                //     var array = [];
                //     this.fields.forEach((fld)=>{
                //         if(fld.type == 'photo'){
                //             array.push({ title: `(${fld.parent}) ${fld.name}`, value: `${this.baseFileURL}/<%= data["${fld.id}"].value %>`})
                //         }
                //     })
                //     array.push({ title: `(Test) Placeholder`, value: `/img/email_placeholder.png`, alt: 'Some Alt Text'})
                //     cb(array);
                // },
                image_class_list: [
                    { title: 'Fit', value: 'force-img-contain' },
                    { title: 'Stretch', value: 'force-img-fill' }
                ],
                importcss_append: true,
                file_picker_types: 'image',
                file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    /*
                    Note: In modern browsers input[type="file"] is functional without
                    even adding it to the DOM, but that might not be the case in some older
                    or quirky browsers like IE, so you might want to add it to the DOM
                    just in case, and visually hide it. And do not forget do remove it
                    once you do not need it anymore.
                    */

                    input.onchange = function () {
                        var file = this.files[0];
                        console.log(file);
                        var reader = new FileReader();
                        reader.onload = function () {
                            /*
                            Note: Now we need to register the blob in TinyMCEs image blob
                            registry. In the next release this part hopefully won't be
                            necessary, as we are looking to handle it internally.
                            */
                            var id = 'blobid' + (new Date()).getTime();
                            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                            var base64 = reader.result.split(',')[1];
                            var blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            /* call the callback and populate the Title field with the file name */
                            cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);

                    };

                    input.click();
                },
                // file_picker_callback: function (callback, value, meta) {
                //     /* Provide file and text for the link dialog */
                //     if (meta.filetype === 'file') {
                //         callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                //     }

                //     /* Provide image and alt text for the image dialog */
                //     if (meta.filetype === 'image') {
                //         callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                //     }

                //     /* Provide alternative source and posted for the media dialog */
                //     if (meta.filetype === 'media') {
                //         callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                //     }
                // },
                templates: [
                        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                        { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                ],
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 600,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                skin: useDarkMode ? 'oxide-dark' : 'oxide',
                content_css: useDarkMode ? 'dark' : 'default',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } ejsinline{ display: none; } span.ejshidden{ display: none; } img.force-img-contain { object-fit: contain; } img.force-img-fill{ object-fit: fill; }',
                init_instance_callback: function (editor) {

                    /* 
                        The following two hacks fix some weirdness with the way the textcolor
                    plugin works - namely, it was attemping to apply color and background-color
                    directly on the element that had the noneditable css class on it instead of putting
                    a span around it as underline does.
                    */
                    editor.formatter.get('forecolor')[0].exact = true;
                    editor.formatter.get('hilitecolor')[0].exact = true;
                }
            }

            this.initValue.setup = (editor)=>{
                editor.on('init', (e)=>{                   
                    editor.setContent(this.startBodyContentValue);                    
                })
                var toggleState = false;
                var $ = tinymce.dom.DomQuery;
                var nonEditableClass = editor.getParam('noneditable_noneditable_class', 'mceNonEditable');
                editor.on('BeforeExecCommand', (e)=>{
                    $(editor.getBody()).find('.' + nonEditableClass).attr('contenteditable', null);
                })
                editor.on('ExecCommand',(e)=>{
                    $(editor.getBody()).find('.' + nonEditableClass).attr('contenteditable', false);
                })
                editor.ui.registry.addMenuButton('variables', {
                    text: 'Variables',
                    fetch: (callback)=> {
                        var items = [];
                        this.ejsVarGroups.forEach((grp)=>{
                            var tmp = {
                                type: 'nestedmenuitem',
                                text: grp,
                            };
                            tmp.getSubmenuItems = ()=>{
                                var subItems = [];
                                var matchingChildren = this.ejsVarsLinear.filter((itm)=>{return itm.parent == grp; })
                                matchingChildren.forEach((itm)=>{
                                    var child = {
                                        type: 'menuitem',
                                        text: itm.name,
                                        onAction: ()=>{
                                            if(itm.type == 'photo'){
                                                editor.insertContent(`<img class="${itm.id} force-img-contain" src="../../../img/email_placeholder.png" alt="${itm.name}" width="256" height="256">`);
                                            }else{
                                                editor.insertContent(`<span class="mceNonEditable"><span class="ejshidden"><%= data["${itm.id}"].value %></span><%# ${itm.name} %></span>`);
                                            }
                                        }
                                    }
                                    subItems.push(child);
                                })
                                return subItems;
                            }
                            items.push(tmp);
                        })
                        callback(items);
                    }
                });
            }
            
            // kill old activeEditor if it exists
            this.tinymce.activeEditor?.destroy();

            // Initialize the new editor
            this.tinymce.init(this.initValue)
            
            this.readyCompleted = true;
            // this.updatePreview();
            // Wait a second then try updating the preview
            setTimeout(()=>{
                this.updatePreview();
            }, 1000)
        },
        updatePreview(){
            this.previewAwaiting = true;
            this.previewAwaitingText = "Fetching Updated Preview - Please Wait";
            if(this.items.length > 0){
                // Compile the input data
                var baseRow = this.items[this.currentlyPreviewing];
                var keys = _.keys(baseRow);
                var row = {};
                keys.forEach((key)=>{
                    // Find match in fields, pull in the field info that we might want to use
                    var results = this.fields.filter((fld)=>{ return fld.id == key; });
                    var match = null;
                    if(results.length > 0){
                        match = results[0];
                    }
                    if(match != null){
                        row[key] = {
                            value: baseRow[key],
                            type: match.type,
                            dataName: match.dataName,
                            name: match.name,
                            parent: match.parent,
                            parentTable: match.parent_table,
                            parentID: match.parent_id
                        };
                    }else{
                        console.log(`Error Durring EJS Render Prep - List Builder Field ID: ${key} could not be found.`)
                        row[key] = {
                            value: null,
                            type: null,
                            dataName: null,
                            name: null,
                            parent: null,
                            parentTable: null,
                            parentID: null
                        };
                    }
                    
                })
                if(this.emailTemplateActive.recipient != null){
                    if(baseRow[this.emailTemplateActive.recipient] == null){
                        this.emailRecipientPreview = '';
                    }else if(baseRow[this.emailTemplateActive.recipient].length > 0){
                        this.emailRecipientPreview = baseRow[this.emailTemplateActive.recipient][0];
                    }else{
                        this.emailRecipientPreview = '';
                    }
                }else{
                    this.emailRecipientPreview = '';
                }
            }
            if(this.tinymce.get("tinymce-editor") != null){
                // Fetching The PreRenderHTML - What would be saved to the DB
                this.preRenderHTML = this.tinymce.get("tinymce-editor").getContent();
                // Applying Image Fix
                var doctype = document.implementation.createDocumentType( 'html', '', '');
                var dom = document.implementation.createDocument('', 'html', doctype);
                dom.documentElement.innerHTML = this.preRenderHTML;
                // This Replaces The Images With The Correct Render Images
                this.fields.forEach((fld)=>{
                    if(fld.type == 'photo'){
                        // Sub the source in the img field with the variable data
                        var allThisFieldElements = dom.getElementsByClassName(`${fld.id}`);
                        for (let i = 0; i < allThisFieldElements.length; i++) {
                            const found = allThisFieldElements[i];
                            if(found.getAttribute('width') > 512 || found.getAttribute('height') > 512){
                                found.setAttribute('src', `${this.baseFileURL}/large_<%= data["${fld.id}"].value %>.png`)
                            }else{
                                found.setAttribute('src', `${this.baseFileURL}/small_<%= data["${fld.id}"].value %>.png`)
                            }
                            found.setAttribute("style", `max-height: ${found.getAttribute('height')}px; max-width: ${found.getAttribute('width')}px;`);
                            found.removeAttribute('width');
                            found.removeAttribute('height');
                            
                            found.setAttribute('alt', `${fld.name}`)
                        }
                    }
                })
                let tmp = dom.documentElement.innerHTML;
                tmp = tmp.replace(/&lt;%=/g,'<%=');
                tmp = tmp.replace(/&lt;%/g,'<%');
                tmp = tmp.replace(/%&gt;/g,'%>');
                tmp = tmp.replace(/data\[\&quot;/g, `data["`);
                tmp = tmp.replace(/\&quot;\]/g, `"]`);

                this.ejsPreppedPreview = tmp;
                this.currentPreviewRow = row;
                this.makePDF();
            }
        },
        filterOptionsAndBuildParentAndChildren(allOptions, parentName){
            this.status = `Gathering ${parentName} Field Options`;
            var build = {
                name: parentName, 
                expanded: false, 
                children: []
            };
            var buildableFields = allOptions.filter((opt)=>{ return opt.ui_group == parentName});
            buildableFields.forEach(fld => {
                build.children.push({
                    name: fld.display_name, 
                    dataName: fld.data_name, 
                    selected: false, 
                    id: fld.id, 
                    type: fld.type, 
                    parent: fld.ui_group, 
                    parent_id: fld.parent_id, 
                    parent_table: fld.parent_table,
                    selections: []
                });
            });
            return build;
        },
        loadSpecifiedList(listID){
            this.status = 'Fetching List Specification';
            this.waiting = true;
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/list_builder/saved/${listID}.json`)
                .then((listResp)=>{
                    var packed = listResp.data.result;
                    this.loadedFromPackage = packed;
                    // Load Save Package With Info
                    this.savePackage.id = packed.id;
                    this.savePackage.name = packed.name;
                    this.savePackage.description = packed.description;
                    this.savePackage.disabled = packed.disabled;
                    if(packed.global_shared){
                        this.savePackage.id = null;
                        this.savePackage.permissions = 'user';
                    }else if(packed.account_shared){
                        if(packed.allow_account_editing){
                            this.savePackage.permissions = 'accountEdit';
                        }else{
                            this.savePackage.permissions = 'account';
                        }
                    }
                    this.loadFromJSON(packed.list)
                })
                .catch((err)=>{
                    console.log(err);
                    butils.createToast(this,'Failed To Fetch Requested Saved List', 'Something went wrong when fetching the requested saved list.', 'danger');
                    this.waiting = false;
                })
        },
        loadFromJSON(json){
            this.status = 'Fetching List Specification';
            // console.log(`Loading From JSON`)
            this.waiting = true;
            // Clear Everything Out
            this.fields = [];
            this.items = [];
            this.filterElems = [];
            this.currentlySortedBy = null;
            this.sortBy = json.sortBy || null;
            this.sortDesc = json.sortDesc || false;
            this.tree.forEach((par)=>{
                par.expanded = false;
                // For Each Parent, Try To Find A Matching Child
                par.children.forEach((child)=>{
                    child.selected = false;
                    child.search = null;
                    child.selection = [];
                })
            })
            
            // EJS Variables Stuff
            this.ejsVarsLinear = [];
            this.ejsVarGroups = [];

            // Handle Each Field
            json.fields.forEach((inp)=>{
                var match = null;
                this.tree.forEach((par)=>{
                    // For Each Parent, Try To Find A Matching Child
                    par.children.forEach((child)=>{
                        if(child.id == inp.id){
                            match = child;
                            child.selected = true;
                            child.selections = inp.selections || [];
                            child.search = inp.search || null;
                        }
                    })
                })
                if(match != null){
                    this.addOrRemoveFromTableFields(match);
                    // Add to EJS Variables
                    this.ejsVarsLinear.push(match);
                    if(_.indexOf(this.ejsVarGroups, match.parent) == -1){
                        this.ejsVarGroups.push(match.parent);
                    }
                }else{
                    // Did not find match for that id
                    console.log(`Could Not Find Match For Field ${inp.id} When Loading From Saved List`)
                }
            })
            this.calculateAvailableParentAttachmentOptions(json.fields);

            if(this.readyCompleted){
                this.checkTemplateFieldsAgainstListFields();
            }
            

            this.filterElems = this.loadFilterRecursive(json.filters);
            this.waiting = false;
            // TODO: Conditional Logic to ensure that list pulled is sane
            if(this.fields.length > 0){
                this.fetch();
            }
        },
        loadFilterRecursive(filt){
            var res = [];
            filt.forEach((fl)=>{
                
                var tmp;
                if(fl.isGroup){
                    tmp = { 
                        id: butils.uuidv4(),
                        fieldName: null,
                        parent: null,
                        operatorName: null,
                        field: null,
                        operator: null,
                        value: null,
                        combination: fl.combination,
                        isGroup: fl.isGroup,
                        negate: ( (_.has(fl,'negate')) ? fl.negate : false ),
                        children: this.loadFilterRecursive(fl.children)
                    }
                    res.push(tmp);
                }else{
                    var field = this.rawAvailableFields.filter((avl)=>{ return avl.id == fl.field })[0];
                    var operator = this.filterOperatorsPerType[field.type].filter((fop)=>{ return fop.value == fl.operator; })[0];
                    tmp = { 
                        id: butils.uuidv4(),
                        fieldName: field.display_name,
                        parent: field.ui_group,
                        operatorName: operator.text,
                        field: fl.field,
                        operator: fl.operator,
                        value: fl.value,
                        combination: fl.combination,
                        isGroup: false,
                        children: []
                    };
                    res.push(tmp);
                }
            })
            return res;
        },
        addOrRemoveFromTableFields(child){
            //Check if removal is required
            var curPos = this.fields.indexOf(child);
            if(curPos != -1){
                var previousParents = _.uniq(_.pluck(this.fields, 'parent'));
                var postParents = _.uniq(_.pluck(this.fields.filter((fld)=>{ return fld != child; }), 'parent'));
                var diff = _.difference(previousParents, postParents);
                // console.log(diff);
                if(diff.length != 0){
                    // Removing this item will remove this parent, check if this alters a filter
                    if(this.filterElems.length > 0){
                        // It might alter a filter
                        var filtersReliantOnThisParent = this.filterElems.filter((flt)=>{return flt.parent == diff[0]});
                        if(filtersReliantOnThisParent.length != 0){
                            var result = confirm("Removing this field will remove one or more filters.");
                            if(result == true){
                                // The user is okay with removing those filters
                                this.filterElems = this.filterElems.filter((flt)=>{return flt.parent != diff[0]});
                                this.fields.splice(curPos, 1);
                            }else{
                                // The user doesnt want to remove those filters
                                child.selected = true;
                            }
                        }else{
                            // There are no filters that rely on this parent
                            this.fields.splice(curPos, 1);
                        }
                    }else{
                        // There are no filters, we can remove parents whenever we want.
                        this.fields.splice(curPos, 1);
                    }
                }else{
                    // No available parent changes
                    this.fields.splice(curPos, 1);
                }
                
            }else{
                // Its not here
                this.fields.push(child);
            }
        },
        packList(){
            var pack = {
                visibility: {
                    filter: this.filterVis,
                    map: this.mapCollapseVis,
                    result: this.resultsVis,
                    parents: []
                },
                map:{
                   selectedFeature: this.mapSelectedFeature,
                   colorBy: this.mapColorBy
                },
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                filters: [],
                fields: []
            };
            
            this.tree.forEach((parent)=>{
                if(parent.expanded){
                    pack.visibility.parents.push(parent.name);
                }
            })

            pack.filters = this.parseFilterRecursively(this.filterElems);

            this.fields.forEach((header, idx)=>{                
                var tmp = {
                    id: header.id,
                    position: idx,
                    selections: header.selections,
                    search: header.search || null
                };

                if (this.sortBy == header.name) {
                    pack.sortBy = header.id;
                }

                pack.fields.push(tmp);
            });
            return pack;
        },
        parseFilterRecursively(elms){
            var packed = [];
            elms.forEach((elm, idx)=>{
                var tmp;
                if(elm.isGroup == true || elm.children.length > 0){
                    
                    tmp = {
                        position: idx,
                        field: elm.field,
                        operator: elm.operator,
                        value: elm.value,
                        combination: elm.combination,
                        isGroup: elm.isGroup,
                        negate: elm.negate,
                        children: this.parseFilterRecursively(elm.children)
                    };
                    if(elms.length - 1 == idx){ tmp.combination = 'NULL';}
                    packed.push(tmp);
                }else{
                    tmp = {
                        position: idx,
                        field: elm.field,
                        operator: elm.operator,
                        value: elm.value,
                        combination: elm.combination,
                        isGroup: elm.isGroup,
                        children: []
                    };
                    if(elms.length - 1 == idx){ tmp.combination = 'NULL';}
                    packed.push(tmp);
                }
            })
            return packed;
        },
        fetch(callback = null){
            this.status = `Running List: ${this.savePackage.name}`;
            this.waiting = true;
            var packed = this.packList();
            packed.labelFieldsWithID = true;
            butils.customInstance.timeoutLength(30000).post(process.env.VUE_APP_API_BASE_URL + "/list_builder/run.json", packed)
            .then((resp)=>{
                var result = resp.data.result;
                this.rawItems = _.clone(result);
                butils.createToast(this, `${result.length} Results`, `Completed Fetch Succesfully with ${result.length} result rows`, 'warning', 10);

                this.localTableDataUpdate(callback);
                this.waiting = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning')
                }else{
                    console.log(err);
                    butils.createToast(this, 'Failed To Fetch List', 'Something went wrong durring the list fetch process.', 'danger');
                    this.waiting = false;
                }
            })
        },
        localTableDataUpdate(callback = null){
            this.status = 'Formatting and Rendering List Results';
            this.waiting = true;
            // Make copy of items
            var tmpItems = _.clone(this.rawItems);
            // Fitler Rows By Header
            // Consider both the search term and the 
            this.fields.forEach((header)=>{
                // console.log(`Handling ${header.name}`)
                var hasSearch = false;
                var searchRegex = null;
                var hasSelections = false;
                if(_.has(header, 'search')){
                    if(header.search != null && header.search != ''){
                        // Search Contains Something, We should be looking for that
                        hasSearch = true;
                        searchRegex = new RegExp(header.search,'i');
                    }
                }
                if(_.has(header, 'selections')){
                    if(header.selections != null && header.selections.length > 0){
                        hasSelections = true;
                    }
                }
                if(hasSelections || hasSearch){
                    tmpItems = _.filter(tmpItems, (itm, idx)=>{ 
                        var match = false;
                        if(hasSearch){
                            if( itm[header.id] != null && itm[header.id].match(searchRegex) != null){
                                match = true;
                            }
                        }
                        if(hasSelections){
                            if( header.selections.includes(itm[header.id]) ){
                                match = true;
                            }
                        }
                        return match;
                    })
                }
            })
            // Update the items
            this.items = tmpItems;
            // Update the viewable items
            this.limitedItemsForDisplay = this.items.slice(0,this.setLimit);
            // Perform the sortBy
            this.performSortBy();
            // Limit visible on screen and handle correctly the more than limit rows, row.
            this.checkAndSetLimitValue();
            this.status = 'Done'
            this.waiting = false;
            if(callback != null){
                callback();
            }
        },
        performSortBy(){
            this.status = 'Sorting List Results';
            this.waiting = true;
            if(this.sortBy != null){
                if(this.currentlySortedBy == this.sortBy){
                    this.items = this.items.reverse();
                    this.limitedItemsForDisplay = this.items.slice(0,this.setLimit);
                }else{
                    // BUG: This can fail when more than one header has the same name
                    let matchingIndex = this.fields.findIndex((header)=>{ return header.name == this.sortBy; });
                    this.sortHeader = this.fields[matchingIndex];

                    if(this.sortHeader.type == 'numeric' || this.sortHeader.type == 'integer' || this.sortHeader.type == 'bigint'){
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = ( _.isNaN(a[this.sortBy]) || a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = ( _.isNaN(a[this.sortBy]) || b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else{
                                let aNum = Number(a[this.sortBy]);
                                let bNum = Number(b[this.sortBy]);
                                if(aNum == bNum){
                                    return 0;
                                }else{
                                    return (aNum < bNum) ? -1 : 1;
                                }
                                
                            }
                        }; 
                    }else if(this.sortHeader.type == 'text' || this.sortHeader.type == 'uuid' || this.sortHeader.type == 'timestamp with time zone' || this.sortHeader.type == 'character' || this.sortHeader.type == 'date'){
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else{
                                let res = a[this.sortBy].localeCompare(b[this.sortBy]);
                                if(res == 0){
                                    return 0;
                                }else{
                                    return (res < 0) ? -1 : 1;
                                }
                            }
                        }; 
                    }else if(this.sortHeader.type == 'text[]'){
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else{
                                let aStr = a[this.sortBy].toString();
                                let bStr = b[this.sortBy].toString();
                                let res = aStr.localeCompare(bStr);
                                if(res == 0){
                                    return 0
                                }else{
                                    return (res < 0) ? -1 : 1;
                                }
                            }
                        };
                    }else if(this.sortHeader.type == 'boolean'){
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else if(!b[this.sortBy]){
                                // if b is false, and not null.
                                // if a is not null, a can never be smaller than b
                                return 1;
                            }else if(b[this.sortBy] == a[this.sortBy]){
                                // if a == b, then a isnt smaller
                                return 0;
                            }else{
                                // b cannot be false, or null.
                                // a is not null, and isnt the same as b
                                // b is true, and a is false, therefore a < b is true
                                return -1;
                            }
                        };
                    }else if(this.sortHeader.type == 'photo' || this.sortHeader.type == 'attachment'){
                        // Does it exist, then by value
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else{
                                if(a[this.sortBy] == b[this.sortBy]){
                                    return 0;
                                }else{
                                    return (a[this.sortBy] < b[this.sortBy]) ? -1 : 1;
                                }
                            }
                        };
                    }

                    // this.items.sort((a,b)=>{
                    //     return this.sortFunction(a,b)
                    // });
                    // if(this.sortDesc){
                    //     this.items = this.items.reverse();
                    // }

                    this.currentlySortedBy = this.sortBy;
                    this.limitedItemsForDisplay = this.items.slice(0,this.setLimit);

                    // Quicksort Implimentation was depicated due to web browsers natively using efficient sorts with good runtimes and memory profiles.
                    // Well, except Safari, which uses a selection sort.
                }
            }
            this.waiting = false;
        },
        checkAndSetLimitValue(){
            if(this.items.length > this.setLimit){
                this.moreThanLimitItems = true;
            }else{
                this.moreThanLimitItems = false;
            }
        },
        getAvailableLists(){
            this.waiting = true;
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + "/list_builder/saved/list.json")
            .then((resp)=>{
                var lists = resp.data.result;
                this.allListOptions = lists;
                this.selectableListsOptions = [];
                this.allListOptions.forEach((lst)=>{
                    this.selectableListsOptions.push({ value: lst.id, text: lst.name});
                })
                this.waiting = false;
            })
            .catch((err)=>{
                console.log(err);
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning')
                }else{
                    butils.createToast(this, 'Failed To Fetch Saved Lists', 'Something went wrong when fetching your list of saved lists.', 'danger');
                }
                this.waiting = false;
            })
        },
        calcSelected(){
            if(this.localListID == null){
                this.listIDSelected = false;
            }else{
                this.listIDSelected = true;
            }
            if(this.localEmailTemplateID == null || this.localEmailTemplateID == 'null' || this.localEmailTemplateID == 'unknown'){
                this.emailTemplateIDSelected = false;
            }else if(this.localEmailTemplateID == 'new'){
                this.setEmailTemplateNew();
            }else{
                this.emailTemplateIDSelected = true;
            }
            if(this.emailTemplateIDSelected){
                if(!this.listIDSelected){
                    this.askIfUsingTemplateDefaultList = true;
                }else{
                    this.startUpComplete = true;
                }
            }
            if(this.isNewEmailTemplate == true &&  this.listIDSelected){
                this.startUpComplete = true;
            }

            if(this.startUpComplete){
                this.callWhenReady();
            }
        },
        setEmailTemplateNew(){
            this.isNewEmailTemplate = true;
            var emptyTemplate = {
                id: null,
                global_shared: false,
                name: null,
                description: null,
                subject_html: "",
                body_html: "",
                account_shared: false,
                allow_account_editing: false,
                approved: false,
                owned_by_me: true,
                shareBehavior: 'Only For Me' 
            };
            this.setActiveEmailTemplate(emptyTemplate);
            this.calcSelected();
        },
        existingEmailTemplateSelected(){
            this.isNewEmailTemplate = false;
            // this.loadEmailTemplate();
            var matches = this.allEmailTemplateOptions.filter((tmp)=>{ return tmp.id == this.localEmailTemplateID});
            var template = matches[0];
            this.setActiveEmailTemplate(template);
            this.calcSelected();
        },
        setActiveEmailTemplate(template){
            this.originalLoadedTemplate = template;
            this.emailTemplateActive ={
                id: template.id,
                owned_by_me: template.owned_by_me,
                account_shared: template.account_shared,
                allow_account_editing: template.allow_account_editing,
                global_shared: template.global_shared,
                name: template.name,
                description: template.description,
                isNotice: template.is_notice,
                noticeType: template.notice_type
            };
            this.emailParentSelected = template.event_parent_field_id;
            if(template.allow_account_editing && template.account_shared && template.owned_by_me){
                this.emailTemplateActive.shareBehavior = "Company-Wide (Editable)";
            }else if(template.account_shared && template.owned_by_me){
                this.emailTemplateActive.shareBehavior = "Company-Wide (Read-Only)";
            }else if(template.owned_by_me){
                this.emailTemplateActive.shareBehavior = "Only For Me";
            }
            this.startBodyContentValue = template.body_html;
        },
        listIDChange(){
            // Load The List and Setup The Options For The Inline Stuff
            if(this.localListID != null){
                this.loadSpecifiedList(this.localListID);
            }
            this.calcSelected();
        },
        loadEmailTemplate(){
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/letter/load/${this.localEmailTemplateID}`)
            .then((resp)=>{
                var template = resp.data.result.template;
                console.log(template);
                this.setActiveEmailTemplate(template);
                this.calcSelected();
            })
            .catch((err)=>{
                console.log(err);
                butils.createToast(this,'Failed To Fetch Letter Template', 'Something went wrong when fetching the letter template from the server.', 'danger');
            })
        },
        updatePermissions(){
            if(this.emailTemplateActive.shareBehavior == "Company-Wide (Editable)"){
                this.emailTemplateActive.allow_account_editing = true;
                this.emailTemplateActive.account_shared = true;
            }else if(this.emailTemplateActive.shareBehavior == "Company-Wide (Read-Only)"){
                this.emailTemplateActive.allow_account_editing = false;
                this.emailTemplateActive.account_shared = true;
            }else if(this.emailTemplateActive.shareBehavior == "Only For Me"){
                this.emailTemplateActive.allow_account_editing = false;
                this.emailTemplateActive.account_shared = false;
            }
        },
        updateTemplate(){
            if(this.tinymce.get("tinymce-editor") != null){
                var currentPreRender = this.tinymce.get("tinymce-editor").getContent()
                var templatePacked = {
                    templateID: this.emailTemplateActive.id,
                    name: this.emailTemplateActive.name,
                    description: this.emailTemplateActive.description,
                    body: currentPreRender,
                    sharedWithAccount: this.emailTemplateActive.account_shared,
                    allowAccountEdit: this.emailTemplateActive.allow_account_editing,
                    defaultListBuilderList: this.localListID,
                    eventParent: this.emailParentSelected,
                    noticeType: this.emailTemplateActive.noticeType
                    
                };
                if(this.emailTemplateActive.noticeType != null){
                    templatePacked.isNotice = true;
                }else{
                    templatePacked.isNotice = false;
                }
                butils.instance.post(process.env.VUE_APP_API_BASE_URL + `/letter/save/template.json`, templatePacked)
                .then((resp)=>{
                    this.setEditorNotDirty();
                    var templateID = resp.data.result.templateID;
                    console.log(`Saved ${templateID}`);
                    butils.createToast(this,'Save Completed', 'Updated to letter template saved', 'success', 3);
                })
                .catch((err)=>{
                    console.log(err);
                    butils.createToast(this,'Failed To Update Letter Template', 'Something went wrong while updating the letter template.', 'danger');
                })
            }else{
                butils.createToast(this, "Failed To Save Template", "Editor Is Invalid");
            }
        },
        saveTemplateCopy(){
             if(this.tinymce.get("tinymce-editor") != null){
                var currentPreRender = this.tinymce.get("tinymce-editor").getContent()
                var templatePacked = {
                    templateID: null,
                    name: this.emailTemplateActive.name,
                    description: this.emailTemplateActive.description,
                    body: currentPreRender,
                    sharedWithAccount: this.emailTemplateActive.account_shared,
                    allowAccountEdit: this.emailTemplateActive.allow_account_editing,
                    defaultListBuilderList: this.localListID,
                    eventParent: this.emailParentSelected,
                    noticeType: this.emailTemplateActive.noticeType
                };
                if(this.emailTemplateActive.noticeType != null){
                    templatePacked.isNotice = true;
                }else{
                    templatePacked.isNotice = false;
                }
                butils.instance.post(process.env.VUE_APP_API_BASE_URL + `/letter/save/template.json`, templatePacked)
                .then((resp)=>{
                    this.setEditorNotDirty();
                    var templateID = resp.data.result.templateID;
                    console.log(`Saved ${templateID}`);
                    this.emailTemplateActive.id = templateID;
                    butils.createToast(this,'Save Completed', 'Letter template saved as a copy', 'success', 3);
                })
                .catch((err)=>{
                    console.log(err);
                    butils.createToast(this,'Failed To Save Copy of Letter Template', 'Something went wrong while saving a copy of the letter template.', 'danger');
                })
                }else{
                    butils.createToast(this, "Failed To Save Template", "Editor Is Invalid");
                }
            
        },
        createNewTemplate(){
            if(this.tinymce.get("tinymce-editor") != null){
                var currentPreRender = this.tinymce.get("tinymce-editor").getContent()
                var templatePacked = {
                    templateID: null,
                    name: this.emailTemplateActive.name,
                    description: this.emailTemplateActive.description,
                    body: currentPreRender,
                    sharedWithAccount: this.emailTemplateActive.account_shared,
                    allowAccountEdit: this.emailTemplateActive.allow_account_editing,
                    defaultListBuilderList: this.localListID,
                    eventParent: this.emailParentSelected,
                    noticeType: this.emailTemplateActive.noticeType
                };
                if(this.emailTemplateActive.noticeType != null){
                    templatePacked.isNotice = true;
                }else{
                    templatePacked.isNotice = false;
                }
                butils.instance.post(process.env.VUE_APP_API_BASE_URL + `/letter/save/template.json`, templatePacked)
                .then((resp)=>{
                    this.setEditorNotDirty();
                    var templateID = resp.data.result.templateID;
                    console.log(`Saved ${templateID}`);
                    this.emailTemplateActive.id = templateID;
                    butils.createToast(this,'Save Completed', 'Letter template saved', 'success', 3);
                    this.isNewEmailTemplate = false;
                })
                .catch((err)=>{
                    console.log(err);
                    butils.createToast(this,'Failed To Save Letter Template', 'Something went wrong while saving the letter template.', 'danger');
                })
            }else{
                butils.createToast(this, "Failed To Save Template", "Editor Is Invalid");
            }
        },
        createEditableCopy(){
            if(this.tinymce.get("tinymce-editor") != null){
                var currentPreRender = this.tinymce.get("tinymce-editor").getContent()
                var templatePacked = {
                    templateID: null,
                    name: this.emailTemplateActive.name + " Copy",
                    description: this.emailTemplateActive.description,
                    body: currentPreRender,
                    sharedWithAccount: false,
                    allowAccountEdit: false,
                    defaultListBuilderList: this.localListID,
                    eventParent: this.emailParentSelected,
                    noticeType: this.emailTemplateActive.noticeType
                };
                if(this.emailTemplateActive.noticeType != null){
                    templatePacked.isNotice = true;
                }else{
                    templatePacked.isNotice = false;
                }
                butils.instance.post(process.env.VUE_APP_API_BASE_URL + `/letter/save/template.json`, templatePacked)
                .then((resp)=>{
                    this.setEditorNotDirty();
                    var templateID = resp.data.result.templateID;
                    console.log(`Saved ${templateID}`);
                    this.emailTemplateActive.id = templateID;
                    this.emailTemplateActive.name = this.emailTemplateActive.name + " Copy";
                    this.emailTemplateActive.sharedWithAccount = false;
                    this.emailTemplateActive.allowAccountEdit = false;
                    butils.createToast(this,'Copy Successful', 'Letter template copied', 'success', 3);
                    this.isNewEmailTemplate = false;
                })
                .catch((err)=>{
                    console.log(err);
                    butils.createToast(this,'Failed To Save Letter Template', 'Something went wrong while saving the letter template.', 'danger');
                })
            }else{
                butils.createToast(this, "Failed To Save Template", "Editor Is Invalid");
            }
            
        },
        checkTemplateFieldsAgainstListFields(showModal = true){
            var generalDataFieldRegex = /\<\%\= data\[\"[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\"\]\.value \%\>/g;
            var uuidOnlyRegex = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;
            let availableFieldsInList = this.loadedFromPackage.list.fields;
            let fieldsRequiredByBody = [];
            let missingFields = [];
            
            var processableBodyHTML = this.originalLoadedTemplate.body_html;
            processableBodyHTML = processableBodyHTML.replace(/&lt;%=/g,'<%=');
            processableBodyHTML = processableBodyHTML.replace(/&lt;%/g,'<%');
            processableBodyHTML = processableBodyHTML.replace(/%&gt;/g,'%>');
            processableBodyHTML = processableBodyHTML.replace(/data\[\&quot;/g, `data["`);
            processableBodyHTML = processableBodyHTML.replace(/\&quot;\]/g, `"]`);
            
            var generalFieldBodyMatches = processableBodyHTML.match(generalDataFieldRegex);

            if(generalFieldBodyMatches != null){
                generalFieldBodyMatches.forEach((genMatch)=>{
                    let res = genMatch.match(uuidOnlyRegex);
                    if(res.length > 0){
                        fieldsRequiredByBody.push(res[0]);
                    }
                })
            }
            
            let availableFieldIDs = _.pluck(availableFieldsInList, 'id');

            fieldsRequiredByBody.forEach((rbs)=>{
                if(!availableFieldIDs.includes(rbs)){
                    missingFields.push({
                        id: rbs,
                        in: "Body"
                    })
                }
            })
            
            if(missingFields.length > 0){
                missingFields.forEach((mf)=>{
                    this.rawAvailableFields.forEach((fld)=>{
                        if(fld.id == mf.id){
                            mf.parent = fld.ui_group;
                            mf.type = fld.type;
                            mf.name = fld.display_name;
                        }
                    })
                })
                // Alert the user with a modal
                if(showModal){
                    this.templateMismatchModal.title = 'Fields Missing From Selected List';
                    this.templateMismatchModal.body = [];
                    missingFields.forEach((mf)=>{
                        this.templateMismatchModal.body.push(`${mf.in} requires ${mf.name} (${mf.parent})`);
                    })
                    this.showTemplateMismatchModal = true;
                }
                return false;
            }else{
                // No missing fields, everything is OK
                return true;
            }
        },
        hideGeneralModal(){
            this.showGenModal = false;
        },
        hideTemplateMismatchModal(){
            this.showTemplateMismatchModal = false;
        },
        hideWarningModal(){
            this.showWarningModal = false;
        },
        saveThenFinalCheckBeforeBatchSend(){
            this.updateTemplate();
            this.finalCheckBeforeBatchSend();
        },
        finalCheckBeforeBatchSend(){
            // Clear out the warn modal
            this.warnModal = {
                title: "Letter Batch Confirmation",
                numberToCreate: null,
                batchName: null,
                cannotBeAttachedDueToMissingParent: 0,
                defaultName: null
            };
            this.warnModal.defaultName = `${this.emailTemplateActive.name} (${this.allListOptions.filter((ll)=>{ return ll.id == this.localListID })[0].name}) ${this.$store.getters.displayName} ${butils.formatters.timestampToDateTime(Date.now())}`
            console.log(this.warnModal.defaultName)
            // Open Modal
            var creating = 0;
            var parents = {}
            parents["__null__"] = 0;
            this.items.forEach((row)=>{
                creating++;
                let parent = row[this.emailParentSelected];
                if(parent == null){
                    parents["__null__"]++;
                }else if(_.has(parents, parent)){
                    parents[parent]++
                }else{
                    parents[parent] = 1;
                }
            })
            if(this.emailParentSelected != null){
                this.warnModal.cannotBeAttachedDueToMissingParent = parents["__null__"];
            }else{
                this.warnModal.cannotBeAttachedDueToMissingParent = 0;
            }
            
            this.warnModal.numberToCreate = creating;

            this.showWarningModal = true;
        },
        sendRequestForBatch(){
            this.showWarningModal = false;
            this.batchSent = true;
            this.batchSending = true;
            var packed = {
                listBuilderListID: this.localListID,
                letterTemplateID: this.emailTemplateActive.id
            };
            if(this.warnModal.batchName == null || this.warnModal.batchName == ''){
                packed.batchName = this.warnModal.defaultName;
            }else{
               packed.batchName = this.warnModal.batchName;
            }
            butils.customInstance.timeoutLength(30000).post(process.env.VUE_APP_API_BASE_URL + "/letter/send/batch", packed)
            .then((response)=>{
                this.batchSending = false;
                console.log(response.data);
            })
            .catch((err)=>{
                butils.createToast(this, "Failed To Send Batch", "Error In Console", "danger");
                console.log(err);
                this.batchSending = false;
            })
        },
        calculateAvailableParentAttachmentOptions(fields){
            this.emailParentOptions = [
                { value: null, text: "Don't Record This Letter On Any Event List"}
            ];

            // if contains device id
            var foundDeviceID = fields.filter((fld)=>{ return fld.id == "36a239c1-8e04-4466-b456-cc86bed17fec"; }) // Finding Device ID
            if(foundDeviceID.length > 0){
                this.emailParentOptions.push({ value: "36a239c1-8e04-4466-b456-cc86bed17fec", text: "Add An Event To The Device Event List"});
            }

            // if contains connection id 
            var foundConnectionID = fields.filter((fld)=>{ return fld.id == "8c1f5986-bb25-4eb5-b2c1-9366d770e256"; }) // Finding Connection ID
            if(foundConnectionID.length > 0){
                this.emailParentOptions.push({ value: "8c1f5986-bb25-4eb5-b2c1-9366d770e256", text: "Add An Event To The Connection Event List"});
            }
        },
        makePDF(){
            this.pdfReady = false;
            var packed = {
                content: this.ejsPreppedPreview,
                data: this.currentPreviewRow
            };
            butils.customInstance.responseType.blob().post(process.env.VUE_APP_API_BASE_URL + `/letter/generate/single`, packed)
            .then(async (response)=>{
                this.previewAwaiting = false;
                var fileURL = URL.createObjectURL(response.data);
                pdfObject.embed(fileURL, "#pdf-preview");
            })
            .catch((err)=>{
                console.log(err)
                butils.createToast(this, "Failed To Generate Letter Preview", "An error occured in the api when attempting to generate pdf, if this problem persists, it might be a problem. Otherwise if the preview has loaded, please ignore this warning.", "warn");
            })
        },
        setEditorNotDirty(){
            if(this.tinymce.get("tinymce-subject-editor") != null){
               this.tinymce.get("tinymce-subject-editor").isNotDirty = true;
            }
            if(this.tinymce.get("tinymce-editor") != null){
                this.tinymce.get("tinymce-editor").isNotDirty = true;
            }
        },
        goToBatchManager(){

        },
        fetchNoticeTypes(){
            this.noticeTypes = [{value: null, text: 'This Letter Is Not A Notice'}]
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/choices/notice_types.json?choices=true`)
            .then((response)=>{
                this.noticeTypes = this.noticeTypes.concat(response.data.result.records);
            })
            .catch((err)=>{
                butils.createToast(this, 'Failed To Fetch Notice Types', 'Details in console', 'danger', 5);
                console.log(err)
            })
        }
    },
    watch:{

    },
    computed:{
        isAnyEditorDirty: function (){
            if(this.readyCompleted){
                if(this.tinymce.get("tinymce-editor") != null){
                    if(this.tinymce.get("tinymce-editor").isDirty()){
                        return true;
                    }
                }
                return false;
            }else{
                return false;
            }
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        this.callWhenReady = _.once(this.ready);
        this.debouncedUpdate = _.debounce(this.updatePreview, 1000);
    },
    mounted(){
        this.fetchNoticeTypes();
        console.log(`Email Builder Props - Letter Template ID: ${this.emailTemplateID} List ID: ${this.listID}`)
        this.localListID = this.listID;
        this.localEmailTemplateID = this.emailTemplateID;
        // If this is not a new list
        if(this.localEmailTemplateID != null && this.localEmailTemplateID != 'unknown' && this.localEmailTemplateID != 'new'){
            this.loadEmailTemplate();
        }else{
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/letter/list/templates`)
            .then((resp)=>{
                this.allEmailTemplateOptions = resp.data.result;
                this.allEmailTemplateOptions.forEach((tmp)=>{
                    this.selectableEmailTemplateOptions.push({ value: tmp.id, text: tmp.name });
                })
                this.calcSelected();
            })
            .catch((err)=>{
                console.log(err);
                butils.createToast(this,'Failed To Fetch List of Email Templates', 'Something went wrong when fetching the list of email templates from the server.', 'danger');
            })
        }
        
        // Load all Available List Builder Lists
        this.getAvailableLists();
        // Fetch the operative fields
        butils.instance.get(process.env.VUE_APP_API_BASE_URL + "/list_builder/fields.json")
        .then((resp)=>{
            var allOptions = resp.data.result;
            this.rawAvailableFields = allOptions;
            var groups = _.pluck(allOptions, "ui_group");
            groups = _.uniq(groups);
            
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Customer"));
            groups.splice(groups.indexOf("Customer"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Site"));
            groups.splice(groups.indexOf("Site"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Connection"));
            groups.splice(groups.indexOf("Connection"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Device"));
            groups.splice(groups.indexOf("Device"),1);
            
            groups.forEach(grp => {
                this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions, grp));
            });
            if(this.localListID != null){
                // Fetch the list requested and load that into the system
                this.loadSpecifiedList(this.localListID);
            }else{
                this.waiting = false;
            }
        })
        .catch((err)=>{
            console.log(err);
            butils.createToast(this,'Failed To Fetch Available Fields', 'Something went wrong when fetching the available fields from the server.', 'danger');
            this.waiting = false;
        })
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){
        clearInterval(this.pdfPreviewInterval);
        clearInterval(this.pdfPreviewTimerInterval);
        clearInterval(this.interval);
    },
    destroyed(){

    }
}
</script>
<style>
span.ejsVariableHidden{
    display: none;
}
img.force-img-contain {
    object-fit: contain;
} 

img.force-img-fill { 
    object-fit: fill;
}
</style>

<style scoped>
#pdf-preview{
    height: 90vh;
}
img {
    vertical-align: initial;
}
.tiny-title{
    font-size: 0.75rem;
    line-height: 0.75rem;
}
#email-to-box{
    padding-top: 5px;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: #F0F0F0;
}
#subject-box{
    padding-top: 5px;
    background-color: #F0F0F0;
}
#body-box{
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #F0F0F0;
}
#preview-email-to{
    border-color: darkgray;
    border-style: solid;
    border-radius: 0.5rem;
    border-width: 2px;
    background-color: white;
    font-size: 1rem;
    line-height: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    min-height: 1.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
#preview-subject{
    border-color: darkgray;
    border-style: solid;
    border-radius: 0.5rem;
    border-width: 2px;
    background-color: white;
    font-size: 1rem;
    line-height: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    min-height: 1.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
#preview-body{
    border-color: darkgray;
    border-style: solid;
    border-radius: 0.5rem;
    border-width: 2px;
    background-color: white;
    font-size: 1rem;
    line-height: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    min-height: 1.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.dont-show-editor{
    display: none;
}
.full-stage-fit{
    height: calc(100vh - 56px);
    width: calc(100vw - 15px);
    overflow-x: hidden;
}
.editor-icon{
}
.editor-and-icon-wrapper{
    background-color: #F0F0F0;
    border: 0.1rem solid darkgray;
    border-radius: 0.5rem;
}
.editor-icons-wrapper{
    background-color: #DCDCDC;
    padding: 0.25rem;
    border-bottom: 0.1rem solid darkgray;
    border-radius: 0.5rem 0.5rem 0 0;
}
.editor-wrapper{
    background-color: white;
}

/* For other boilerplate styles, see: /docs/general-configuration-guide/boilerplate-content-css/ */
/*
* For rendering images inserted using the image plugin.
* Includes image captions using the HTML5 figure element.
*/

figure.image {
  display: inline-block;
  border: 1px solid gray;
  margin: 0 2px 0 1px;
  background: #f5f2f0;
}

figure.align-left {
  float: left;
}

figure.align-right {
  float: right;
}

figure.image img {
  margin: 8px 8px 0 8px;
}

figure.image figcaption {
  margin: 6px 8px 6px 8px;
  text-align: center;
}

/*
 Alignment using classes rather than inline styles
 check out the "formats" option
*/

img.align-left {
  float: left;
}

img.align-right {
  float: right;
}

/* Basic styles for Table of Contents plugin (toc) */
.mce-toc {
  border: 1px solid gray;
}

.mce-toc h2 {
  margin: 4px;
}

.mce-toc li {
  list-style-type: none;
}
</style>